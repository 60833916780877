import React, { memo } from "react";
import Sidebar from "./sidebar";
import Header from "./header";

const Layout = ({ children }) => {
  return (
    <div className="flex w-full h-[100vh]  overflow-hidden ">
      <Sidebar />
      <div className="bg-[#fdfdff] rounded-[16px] min-w-[90%] max-w-[100%] flex flex-col justify-between py-1 flex-1">
        <Header />
        <div className="flex flex-1 w-full h-full  overflow-hidden border-t border-t-[#e8ebe9]  mt-2">
          {children}
        </div>
      </div>
    </div>
  );
};

export default memo(Layout);
