import React, { useState, useEffect } from "react";
import fetchWithToken from "../../utils/api";
import { useTranslation } from "react-i18next";

interface UserData {
  value: string;
  label: string;
}

const RotationExport = ({
  isRotationExport,
  setRotationExport,
}: {
  isRotationExport: boolean;
  setRotationExport: (isOpen: boolean) => void;
}) => {
  const [users, setUsers] = useState<UserData[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await fetchWithToken("/users?limit=100", {
          method: "GET",
        });
        setUsers(
          data?.data?.map((user: any) => ({
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
          }))
        );
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (isRotationExport) fetchUsers();
  }, [isRotationExport]);

  const handleRotationExport = async () => {
    try {
      const blob = await fetchWithToken("/export/schedulesRule/rotation", {
        method: "GET",
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div>
      {isRotationExport && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setRotationExport(false)}
        >
          <div
            className="bg-white w-[400px] p-6 rounded shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-bold mb-4">{t("Export Rotation")}</h3>
            <div className="mb-8 font-semibold">
              {t("ConfirmExportRotationSchedule")}
            </div>

            <button
              onClick={handleRotationExport}
              className="bg-green-500 hover:bg-green-700 text-white w-full p-2 rounded"
            >
              {t("Export Rotation")}
            </button>

            <div className="mt-4">
              <button
                onClick={() => setRotationExport(false)}
                className="w-full border border-gray-500 text-gray-500 py-2 rounded mt-0"
              >
                {t("Close")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RotationExport;
