import React, { useEffect, useState } from 'react'
import FullCalendarComponent, { CalendarEvent } from '../full-calendar/fullCalendar';
import fetchWithToken from '../../utils/api';
import moment from 'moment';
import { dateToFormatedTime, getRandomLightColor } from '../../utils/functions';
interface Schedule {
  id: number;
  date: string;
  deptId: number;
  shift_id: string
  shift: {
    id: number;
    start_time: string;
    end_time: string;
    shift_type: string;
    createdAt: string;
    date: string;
  };
}
interface Shift {
  id: number;
  start_time: string;
  end_time: string;
  shift_type: string;
  createdAt: string;
  date: string;
};
function NewCalendarDesign() {
  const [shifts, setShifts] = useState<CalendarEvent[]>([]);
  const [isFetchingSchedules, setIsFetchingSchedules] = useState<boolean>(false)
  const [currentDate, setCurrentDate] = useState<moment.Moment>(moment());

  useEffect(() => {
    fetchschedules()
  }, [currentDate])
  const fetchschedules = async () => {
    const firstDayOfCurrentMonth = currentDate.clone().startOf("month").format("YYYY-MM-DD");
    const firstDayOfNextMonth = currentDate.clone().add(1, "month").startOf("month").format("YYYY-MM-DD");
    setIsFetchingSchedules(true)
    try {
      const data = await fetchWithToken(`/schedules?startDate=${firstDayOfCurrentMonth}&endDate=${firstDayOfNextMonth}`, {
        method: "GET",
      });
      setShifts(mapShiftsToEvents(data?.data || []))
      setIsFetchingSchedules(false)
    } catch (error) {
      console.error("Failed to fetch schedules:", error);
      setIsFetchingSchedules(false)

    }
  };
  const nextMonth = () => {
    let nextMonthDate = moment(currentDate.add(1, "month"))
    setCurrentDate(nextMonthDate);
  }
  const lastMonth = () => {
    let prevMonthDate = moment(currentDate.subtract(1, "month"))
    setCurrentDate(prevMonthDate);
  }
  const mapShiftsToEvents = (schedule): CalendarEvent[] => {
    if (!!schedule.length) {
      let arr: CalendarEvent[] = []
      schedule.forEach((schedule: Schedule) => {
        let startTime = dateToFormatedTime(schedule.shift.start_time, schedule.date);
        let endTime = dateToFormatedTime(schedule.shift.end_time, schedule.date);
        let shiftTypeTitle = schedule.shift.shift_type;
        let durationTitle = `${startTime} - ${endTime}`
        let id = (new Date()).getMilliseconds().toString();
        let classNames = getRandomLightColor()
        let start = schedule.date;
        arr.push({ id, start, title: `${durationTitle} - ${shiftTypeTitle}`,classNames})
      })
      console.log(arr);
      
      return arr
    } return []
  }
  const [datesClicked, setDatesClicked] = useState<any[]>([])
  // dictionary
  // const onDateClicked = (obj)=>{
  //   let id = new Date (obj.date).getTime()
  //   if(!datesClicked[id]){
  //     setDatesClicked({...datesClicked,[id]:obj})
  //     obj.dayEl.style.backgroundColor="#8954f3"
  //   }else{
  //     let tempDatesClicked = datesClicked;
  //     delete tempDatesClicked[id]
  //     setDatesClicked(tempDatesClicked)
  //     obj.dayEl.style.backgroundColor="white"
  //   }
  //   console.log(datesClicked);
  // }
  const onDateClicked = (obj:any)=>{
    // if(!datesClicked.length){
    //   setDatesClicked([obj])
    //   obj.dayEl.style.backgroundColor="#8954f3"
    // }else{
    //   setDatesClicked([...datesClicked,obj])
    //   obj.dayEl.style.backgroundColor="#8954f3"
    //   // datesClicked[0].dayEl.style.backgroundColor="white"
    //   // setDatesClicked([])
    // }
  }
  return (
    <>
      <FullCalendarComponent
        calendarEvents={shifts}
        nextMonthHandler={nextMonth}
        lastMonthHandler={lastMonth}
        dateClickHandler={onDateClicked}

      />
    </>
  )
}

export default NewCalendarDesign