import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as DashboardIcon } from "../assets/dashboard.svg";
import { ReactComponent as CrudsIcon } from "../assets/cruds.svg";
import { ReactComponent as SwapIcon } from "../assets/swap.svg";
import { ReactComponent as RotationIcon } from "../assets/Rotation.svg";
import { ReactComponent as HospitalIcon } from "../assets/Hospital.svg";
import leftarrowsideIcon from "../assets/LeftArrowS.svg";
import rightarrowsideIcon from "../assets/RightArrowS.svg";
import calendarIcon from "../assets/calendarS.svg";
import swaprequestIcon from "../assets/swaprequestS.svg";
import crudsIcon from "../assets/crudsS.svg";
import rotationIcon from "../assets/rotationS.svg";

const Sidebar = () => {
  const userPermissions = useSelector((state: any) => state?.user?.permissions);
  const userRole = useSelector((state: any) => state?.user?.user?.role);
  const isAdmin = true;

  const navigate = useNavigate();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { t } = useTranslation();
  const isActive = (path: string) => location.pathname === path;

  const sidebarWidth = isCollapsed ? "w-[70px]" : "w-[240px]";

  return (
    <div
      className={`bg-gradient-to-r from-[#6F48D7] to-[#4D3DBB] flex flex-col items-center py-5 transition-all duration-300  rounded-tr-3xl rounded-br-3xl ${sidebarWidth} relative`}
    >
      <div className="flex flex-col items-center w-full">
        <div
          className="cursor-pointer flex justify-center w-full mt-5"
          onClick={() => navigate(`/dashboard`)}
        >
          <img
            alt="logo"
            src={
              isCollapsed
                ? require("../assets/logo3.png")
                : require("../assets/logo2.png")
            }
            className="transition-all duration-200 w-[150px] md:w-[120px]"
          />
        </div>
        
        {/* Divider and Collapse Button */}
        <div className="flex items-center justify-between w-full mt-2">
          <div className="w-full border-b-[1px] border-white"></div>
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="p-0 m-0 border-none bg-transparent cursor-pointer w-15 h-15"
          >
            <img
              src={isCollapsed ? rightarrowsideIcon : leftarrowsideIcon}
              alt="toggle arrow"
              className="w-15 h-15 "
            />
          </button>
        </div>

        {/* Sidebar Links */}
        <div className="flex flex-col items-center mt-3 w-full align-middle">
          <SidebarItem
            icon={calendarIcon}
            label={t("Calendar")}
            path="/dashboard"
            isActive={isActive("/dashboard")}
            isCollapsed={isCollapsed}
            onClick={() => navigate(`/dashboard`)}
          />

          {true && (
            <SidebarItem
              icon={swaprequestIcon}
              label={t("Swap Requests")}
              path="/swap"
              isActive={isActive("/swap")}
              isCollapsed={isCollapsed}
              onClick={() => navigate(`/swap`)}
            />
          )}

          {(userRole === "manager" || userRole === "admin") && (
            <SidebarItem
              icon={rotationIcon}
              label={t("rotation")}
              path="/rotation"
              isActive={isActive("/rotation")}
              isCollapsed={isCollapsed}
              onClick={() => navigate(`/rotation`)}
            />
          )}

          {isAdmin && (
            <SidebarItem
              icon={crudsIcon}
              label={t("Settings")}
              path="/cruds"
              isActive={isActive("/cruds")}
              isCollapsed={isCollapsed}
              onClick={() => navigate(`/cruds`)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SidebarItem = ({ icon, label, path, isActive, isCollapsed, onClick }) => (
  <div
    className={`flex items-center justify-between cursor-pointer rounded-r-lg 
    transition-all duration-200 rounded-tr-xl rounded-br-xl mr-5
    ${isActive ? "bg-darkBlue" : ""}`}
    onClick={onClick}
    style={{
      padding: "8px 16px",
      marginBottom: "15px",
      width: "100%",
      // marginLeft: "10px",
      // marginRight: "24px",
    }}
  >
    <div
      className="flex items-center justify-center 
      w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 lg:w-10 lg:h-10"
    >
      <img src={icon} alt={label} className="w-full h-full object-contain" />
    </div>
    {!isCollapsed && (
      <span className="text-white text-sm md:text-base ml-3 flex-grow text-center">
        {label}
      </span>
    )}
  </div>
);

export default Sidebar;
