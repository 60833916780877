import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"

import "./style.css";
import React, { useEffect } from "react";
export interface CalendarEvent {
  id?: string;
  title?: string;
  backgroundColor?: string;
  allDay?: boolean;
  start?: string;
  end?: string;
  textColor?: string;
  classNames?: string;
}
function FullCalendarComponent(props: { calendarEvents: CalendarEvent[],nextMonthHandler , lastMonthHandler,dateClickHandler }) {
    useEffect(() => {
        let nextButton = document.querySelectorAll("button.fc-next-button")[0]
        let prevButton = document.querySelectorAll("button.fc-prev-button")[0]
        if(!!nextButton)
        nextButton.addEventListener('click',()=>{
            props.nextMonthHandler()
        })       
        if(!!prevButton)
        prevButton.addEventListener('click',()=>{
            props.lastMonthHandler()
        })
        return ()=>{
        }

    }, [])    

  return (
    <>
      <FullCalendar
        eventMaxStack={1}
        nowIndicator={true}
        titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
        plugins={[dayGridPlugin,interactionPlugin]}
        events={props.calendarEvents}
        dateClick={props.dateClickHandler}
      />
    </>
  );
}

export default FullCalendarComponent;
