import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Schedule from "../../components/schedule";
import fetchWithToken from "../../utils/api";
import ScheduleTable from "../../components/scheduleMonth";
import UpComingShifts from "../../components/upComingShifts";
import SwapRequests from "../../components/SwapRequests";
import { useSelector } from "react-redux";
import Spinner from "../../components/spinner";
import NewCalendarDesign from "../../components/new-calendar/newCalendarDesign";
import DashboardSideWrapperComponent from "../../components/dashboardSideWrapperComponent/dashboardSideWrapperComponent";

const Dashboard = () => {
  const UserPermissions = useSelector((state: any) => state?.user?.permissions);
  // const hasListSchedule = Array.isArray(UserPermissions) && UserPermissions.includes("list schedule");
  // const hasListShifts = Array.isArray(UserPermissions) && UserPermissions.includes("list shift");
  // const hasListSwapRequests = Array.isArray(UserPermissions) && UserPermissions.includes("list swap_requests");
  const hasListSchedule = true;
  const hasListShifts = true;
  const hasListSwapRequests = true;
  const [nextShift, setNextShift] = useState<any>(null);
  const [countdown, setCountdown] = useState("");
  const { t, i18n } = useTranslation();
  const [nextShitLoading, setNextShitLoading] = useState<boolean>(false)
  const fetchNextShift = async () => {
    setNextShitLoading(true);
    try {
      const data = await fetchWithToken("/schedules/upcoming", {
        method: "GET",
      });
      setNextShift(data?.data[0]);
      setNextShitLoading(false);
    } catch (error) {
      console.error("Failed to fetch next shift:", error);
      setNextShitLoading(false);
    }
  };

  useEffect(() => {
    fetchNextShift();
  }, []);

  useEffect(() => {
    if (!nextShift) return;
    const shiftStartTime = moment.utc(
      `${nextShift.date.split("T")[0]} ${nextShift.shift.start_time}`,
      "YYYY-MM-DD HH:mm:ss"
    );
    const updateCountdown = () => {
      const now = moment();
      const duration = moment.duration(shiftStartTime.diff(now));
      if (duration.asSeconds() > 0) {
        const hours = String(duration.hours()).padStart(2, "0");
        const minutes = String(duration.minutes()).padStart(2, "0");
        const seconds = String(duration.seconds()).padStart(2, "0");
        setCountdown(`${hours}:${minutes}:${seconds}`);
      } else {
        setCountdown("00:00:00");
      }
    };
    const intervalId = setInterval(updateCountdown, 1000);
    updateCountdown();
    return () => clearInterval(intervalId);
  }, [nextShift]);

  return (
    <div className="w-full h-full flex-col flex flex-1 overflow-hidden px-[6px]">
      {hasListShifts && (
        <div className=" border-2 border-[#138EE9]   lg:h-[55px] h-auto flex flex-col md:flex-row items-center justify-center font-[500] text-[16px] md:text-[24px] rounded-[20px] mt-[4px] mb-[16px] px-4 py-2 md:py-0">
          {!!nextShitLoading ? <> <Spinner/> </> :
          <>
         <span>{t("NextShiftMessage")}</span>
          <div className="flex-grow text-[#636363] text-right">
            <span className="  font-[700] mx-2 text-[#239FFC]">
              {countdown || 0}
            </span>
            {t("NextShiftMessageh")}
          </div>          
          </>
          }
 
        </div>
      )}
      <div className="w-full h-full flex flex-1 lg:overflow-hidden overflow-y-auto lg:flex-row flex-col">
        <div className="flex flex-col min-w-[50%] max-w-full w-full lg:overflow-y-scroll scrollbar-hidden mr-[20px]">
          {hasListSchedule && (
            <>
              <NewCalendarDesign />
            </>
          )}
        </div>
        <div className="max-w-[30%] min-w-[420px] max-h-full">
          <div className="overflow-y-scroll scrollbar-hidden h-full">
            {<DashboardSideWrapperComponent/>}
            {/* {hasListShifts && <UpComingShifts />}
            {hasListSwapRequests && <SwapRequests />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
