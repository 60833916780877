"use client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import Select from "react-select";

interface SelectOption {
  value: string | number;
  label: string;
}
const UsersExport = ({ isUsersExport, setIsUsersExport, fetchProfiles }) => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<SelectOption | null>(null);
  const [users, setUsers] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = async () => {
    if (!selectedUser) {
      alert("Please select a user.");
      return;
    }

    setIsLoading(true);

    try {
      const blob = await fetchWithToken(
        `/export/users?user_id=${selectedUser.value}`,
        {
          method: "GET",
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const data = await fetchWithToken("/users?limit=100", {
          method: "GET",
        });
        setUsers(
          data?.data?.map((user: any) => ({
            value: user._id,
            label: `${user.first_name} ${user.last_name}`,
          }))
        );
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (isUsersExport) fetchProfiles();
  }, [isUsersExport]);

  return (
    <div>
      {isUsersExport && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setIsUsersExport(false)}
        >
          <div
            className="bg-white w-[400px] p-6 rounded shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="text-lg font-bold mb-4">{t("Export User")}</h3>

            <div className="mb-4">
              <label
                htmlFor="userSelect"
                className="block text-sm font-medium text-gray-700"
              >
                {t("SelectUser")}
              </label>
              <Select
                id="userSelect"
                options={users}
                value={selectedUser}
                onChange={(option: SelectOption | null) =>
                  setSelectedUser(option)
                }
                placeholder={t("Select")}
                isDisabled={isLoading}
              />
            </div>
            <button
              onClick={handleExport}
              disabled={isLoading}
              className="bg-green-500 hover:bg-green-700 text-white w-full p-2 rounded"
            >
              {isLoading ? t("Exporting...") : t("Export User")}
            </button>
            <button
              onClick={() => setIsUsersExport(false)}
              className="w-full border border-gray-500 text-gray-500 py-2 rounded mt-2"
            >
              {t("Close")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersExport;
