import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-dropdown/style.css";
import Select from "react-select";
import {
  City,
  Country,
  ICity,
  ICountry,
  IState,
  State,
} from "country-state-city";
import AnimatedBtn from "../animatedBtn";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";

interface LocationProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  fetchLocations: () => void;
}

interface FormValues {
  name: string;
  addr_one: string;
  addr_two: string;
  country: SelectOption | ICountry | null;
  city: SelectOption | ICity | null;
  state: SelectOption | IState | null;
  postal_code: string;
}

interface SelectOption {
  value: string;
  label: string;
  name: string | null;
  isoCode: string | any;
}

const Location: any = ({ isModalVisible, setModalVisible, fetchLocations }) => {
  const isAdd = isModalVisible === true;
  const [stateList, setStateList] = useState<SelectOption[]>();
  const [citiesList, setCitiesList] = useState<SelectOption[]>();
  const [status, setStatus] = useState<string>("");
  const [isDecline, setIsDecline] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const countries = Country.getAllCountries()?.map((each: ICountry) => ({
    ...each,
    value: each?.isoCode,
    label: each?.name,
  }));

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      addr_one: "",
      addr_two: "",
      country: null,
      city: null,
      state: null,
      postal_code: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Required")),
      addr_one: Yup.string().required(t("Required")),
      addr_two: Yup.string().required(t("Required")),
      country: Yup.object({
        value: Yup.string().required(t("RequiredValidation")),
      }).required(t("Required")),
      state: Yup.object({
        value: Yup.string().required(t("RequiredValidation")),
      }).required(t("Required")),
      city: Yup.object({
        value: Yup.string().required(t("RequiredValidation")),
      }).required(t("Required")),
      postal_code: Yup.string().required(t("Required")),
    }),

    onSubmit: async (values) => {
      setStatus("onclic");
      try {
        await fetchWithToken(
          isAdd ? "/locations" : `/locations/${isModalVisible}`,
          {
            method: isAdd ? "POST" : "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: values?.name,
              addr_one: values?.addr_one,
              addr_two: values?.addr_two,
              country: values?.country?.name,
              state: values?.state?.name,
              city: values?.city?.name,
              postal_code: values?.postal_code.toString(),
            }),
          }
        );
        setStatus("success");
        setTimeout(() => {
          setModalVisible(!isModalVisible);
        }, 1250);
        fetchLocations();
      } catch (error) {
        setStatus("fail");
        //console.error("Error creating locations:", error);
        console.error(t("Error creating locations:"), error);
      }
    },
  });

  const getLocationDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/locations/${id}`, {
        method: "GET",
      });
      formik?.setFieldValue("name", data.data?.name);
      formik?.setFieldValue("addr_one", data.data?.addr_one);
      formik?.setFieldValue("addr_two", data.data?.addr_two);
      formik?.setFieldValue(
        "country",
        countries?.filter((each) => each?.name === data.data?.country)[0]
      );
      formik?.setFieldValue("state", data.data?.state);
      formik?.setFieldValue("city", data.data?.city);
      formik?.setFieldValue("postal_code", data.data?.postal_code);
    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
    }
  };

  useEffect(() => {
    if (formik?.values?.country?.isoCode) {
      setStateList(
        State.getStatesOfCountry(formik?.values?.country?.isoCode)?.map(
          (each: IState) => ({
            ...each,
            value: each?.isoCode,
            label: each?.name,
          })
        )
      );
    }
  }, [formik?.values?.country]);

  useEffect(() => {
    if (typeof formik?.values?.state === typeof "") {
      const state = formik?.values?.state;
      formik?.setFieldValue(
        "state",
        stateList?.filter((each) => {
          return each?.name === state;
        })[0]
      );
    }
  }, [stateList]);

  useEffect(() => {
    if (typeof formik?.values?.city === typeof "") {
      const city = formik?.values?.city;
      formik?.setFieldValue(
        "city",
        citiesList?.filter((each) => {
          return each?.name === city;
        })[0]
      );
    }
  }, [citiesList]);

  useEffect(() => {
    if (formik?.values?.state?.isoCode && formik?.values?.state?.isoCode)
      setCitiesList(
        City.getCitiesOfState(
          formik?.values?.country?.isoCode,
          formik?.values?.state?.isoCode
        )?.map((each: any) => ({
          ...each,
          value: each?.name,
          label: each?.name,
        }))
      );
  }, [formik?.values?.state]);

  useEffect(() => {
    formik?.resetForm();
    if (
      typeof isModalVisible === "number" ||
      typeof isModalVisible === "string"
    )
      getLocationDetails(isModalVisible);
  }, [isModalVisible]);

  return (
    isModalVisible && (
      <main
        className="fixed top-0 bottom-0 left-0 right-0 
        bg-black bg-opacity-30 flex justify-center items-center z-[1]"
        onClick={() => setModalVisible(!isModalVisible)}
      >
        <div
          className="py-5 max-w-[100%] h-auto sm:max-w-[60%] sm:h-[85%] 
          overflow-auto m-auto w-full sm:w-[385px] capitalize 
          bg-[#FFF] rounded-[8px] flex flex-col items-center scrollbar-hidden"
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="text-center text-lg font-bold">
            {!isAdd ? t("Edit") : t("Add")}
          </div>
          <div className="text-sm text-[#101010] w-full px-5">
            <div className="font-bold">{t("HospitalName")}</div>
            <input
              type="text"
              placeholder={t("HospitalNameLabel")}
              name="name"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="name"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.name}
              style={{
                borderColor:
                  formik?.touched?.name && formik?.errors?.name
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.name && formik?.errors?.name && (
                <div>{formik?.errors?.name}</div>
              )}
            </div>
            <div className="font-bold">{t("AddressLine1")}</div>
            <input
              type="text"
              placeholder={t("AddressLine1Label")}
              name="addr_one"
              required
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="addr_one"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.addr_one}
              style={{
                borderColor:
                  formik?.touched?.addr_one && formik?.errors?.addr_one
                    ? "#E23121"
                    : "#5D6561",
              }}
            />

            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.addr_one && formik?.errors?.addr_one && (
                <div>{formik?.errors?.addr_one}</div>
              )}
            </div>
            <div className="font-bold">{t("AddressLine2")}</div>
            <input
              type="text"
              placeholder={t("AddressLine2Label")}
              name="addr_two"
              required
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="addr_two"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.addr_two}
              style={{
                borderColor:
                  formik?.touched?.addr_two && formik?.errors?.addr_two
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.addr_two && formik?.errors?.addr_two && (
                <div>{formik?.errors?.addr_two}</div>
              )}
            </div>
            <div className="font-bold">{t("Country")}</div>
            <Select
              placeholder={t("Select")}
              options={countries}
              value={formik.values.country}
              name="country"
              onChange={(option) => formik.setFieldValue("country", option)}
              onBlur={formik.handleBlur}
              className="w-[350px] h-[40px]"
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor:
                    formik.touched.country && formik.errors.country
                      ? "#E23121"
                      : "#5D6561",
                }),
              }}
            />

            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik.touched.country && formik.errors.country && (
                <div>{formik.errors.country}</div>
              )}
            </div>
            <div className="font-bold">{t("State")}</div>
            <Select
              placeholder={t("Select")}
              options={stateList}
              value={formik.values.state}
              name="state"
              onChange={(option) => formik.setFieldValue("state", option)}
              onBlur={formik.handleBlur}
              className="w-[350px] h-[40px]"
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor:
                    formik.touched.state && formik.errors.state
                      ? "#E23121"
                      : "#5D6561",
                }),
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik.touched.state && formik.errors.state && (
                <div>{formik.errors.state}</div>
              )}
            </div>
            <div className="font-bold">{t("City")}</div>
            <Select
              placeholder={t("Select")}
              options={citiesList}
              value={formik.values.city}
              name="city"
              onBlur={formik?.handleBlur}
              onChange={(option) => formik.setFieldValue("city", option)}
              className="w-[350px] h-[40px]"
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor:
                    formik.touched.city && formik.errors.city
                      ? "#E23121"
                      : "#5D6561",
                }),
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik.touched.city && formik.errors.city && (
                <div>{formik.errors.city}</div>
              )}
            </div>
            <div className="font-bold">{t("PostalCode")}</div>
            <input
              type="text"
              placeholder={t("Select")}
              name="postal_code"
              required
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="postal_code"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.postal_code}
              style={{
                borderColor:
                  formik?.touched?.postal_code && formik?.errors?.postal_code
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.postal_code && formik?.errors?.postal_code && (
                <div>{formik?.errors?.postal_code}</div>
              )}
            </div>
          </div>
          <div className="w-[350px] flex justify-between mt-1">
            <button
              type="button"
              onClick={() => {
                setModalVisible(false);
                setIsDecline(true);
              }}
              className={`w-[168px] h-[40px] rounded-[8px] border border-darkBlue text-darkBlue hover:border-[#EBEEFF] hover:text-darkBlue ${
                isDecline ? "hover:bg-[#1A275E] text-white]" : ""
              } text-[16px] font-[700] px-[24px] py-[8px]`}
            >
              {t("Cancel")}
            </button> 
            <div className="w-[168px] ">
              <AnimatedBtn
                txt={t("Confirm")}
                status={status}
                setStatus={setStatus}
                onClick={(e: any) => {
                  formik.handleSubmit();
                }}
              />
            </div>
          </div>
        </div>
      </main>
    )
  );
};

export default Location;
