import React, { useState } from 'react';
import * as XLSX from 'xlsx'; 
import fetchWithToken from '../../utils/api';
import { useTranslation } from "react-i18next";

interface UserData {
  ID: string;
  Name: string;
  Email: string;
  Role: string;
  Contact: string;
  'Work Contact': string;
  Birthdate: string;
  Department: string;
  Position: string;
  Level: string;
  Manager: string;
}

function ImportUsers({
  isImportUser,
  setIsImportUser,
  fetchProfiles,
}) {
  const [file, setFile] = useState<File | null>(null); 
  const [isUploading, setIsUploading] = useState(false); 
  const [message, setMessage] = useState(""); 
  const { t } = useTranslation();
  const [usersData, setUsersData] = useState<UserData[]>([]); 


  const truncateFileName = (name: string) => {
    return name.length > 20 ? name.slice(0, 17) + "..." : name;
  };



  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] ?? null;
    if (
      selectedFile &&
      ![
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ].includes(selectedFile.type)
    ) {
      setMessage(t("Invalid file type. Please upload an Excel file."));
      setFile(null);
      return;
    }

    setFile(selectedFile);
    setMessage(""); 

   
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target?.result;
      if (!binaryStr) {
        setMessage(t("Failed to read file."));
        return;
      }

      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const data: UserData[] = XLSX.utils.sheet_to_json(worksheet);
      setUsersData(data); 
    };

    if (selectedFile) {
      reader.readAsBinaryString(selectedFile);
    }
  };

 
  const prepareUsersData = (): UserData[] => {
    return usersData.map((item) => ({
      ID: item.ID,
      Name: item.Name,
      Email: item.Email,
      Role: item.Role,
      Contact: item.Contact,
      'Work Contact': item['Work Contact'],
      Birthdate: item.Birthdate,
      Department: item.Department,
      Position: item.Position,
      Level: item.Level,
      Manager: item.Manager,
    }));
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage(t("Please select a file to upload."));
      return;
    }
  
    const blob = new Blob([file], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  
    const formData = new FormData();
    formData.append("file", blob, file.name);
  
    setIsUploading(true);
    setMessage("");
  
    try {
      const response = await fetchWithToken("/upload", {
        method: "POST",
        body: formData,
      });
  
      if (response.data?.file) {
        setMessage(t("File uploaded successfully! Users are being processed."));
        console.log("File URL:", response.data.file);
      } else if (response.message) {
        setMessage(response.message); 
      } else {
        setMessage(t("Failed to import users. Please check the file and try again."));
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage(t("Failed to import users. Please try again later."));
    } finally {
      setIsUploading(false); 
    }
  };

 
 
  return (
    <div>
    
    {
    isImportUser && (
  <div
    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    onClick={() => setIsImportUser(false)} 
  >
    <div
      className="bg-white w-[400px] p-6 rounded shadow-lg"
      onClick={(e) => e.stopPropagation()} 
    >
      <h3 className="text-lg font-bold mb-4">{t("Importusers")}</h3>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileChange}
        disabled={isUploading}
        className="mb-4 block w-full text-sm"
      />
        {file && (
              <div className="mb-4 bg-gray-100 p-2 rounded">
                <p className="text-sm font-medium">{t("AttachedFile:")}</p>
                <p className="text-sm text-blue-600">
                  {truncateFileName(file.name)}
                </p>
              </div>
            )}
      <button
        onClick={handleUpload}
        disabled={isUploading || !file}
            className="bg-[#00a843] hover:bg-[#389365] w-[180px] h-[40px] rounded-[8px] text-[16px] font-[700] flex items-center justify-center text-[#fff]"
      >
        {isUploading ? t("Uploading...") : t("Importusers")}
      </button>
      {isUploading && <p className="mt-4">{t("Uploading, please wait...")}</p>}
      {message && <p className="mt-4 text-red-600">{message}</p>}
    </div>
  </div>
)}
  </div>
  );
}

export default ImportUsers;
