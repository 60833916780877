import { useEffect, useState } from "react";
import Profile from "./modals/profile";
import Notifications from "./modals/notifications";
import Chats from "./modals/chats";
import Logout from "./modals/logout";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../utils/api";
import { setAdminStatus, setUser, setUserPermissions } from "../utils/redux";
import notificationIcon from "../assets/notificationP.svg";
import meesageIcon from "../assets/messageP.svg";
import arrowdownIcon from "../assets/arrowdownP.svg";
import saudiIcon from "../assets/saudi.svg";
import usaIcon from "../assets/usa.svg";
const Header = () => {
  const dispatch = useDispatch();
  const UserRoleId = useSelector((state: any) => state?.user?.role_id);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showChats, setShowChats] = useState<boolean>(false);
  const [isModalVisible, setModalVisible] = useState<string | boolean>(false);
  const [userName, setUserName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [profileimg, setProfileimg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDropdown, setIsDropdown] = useState<boolean>(false);
  const [isLogout, setIsLogout] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [isLocaleDropdownOpen, setIsLocaleDropdownOpen] = useState<boolean>(false);

  const userdata = async () => {
    setLoading(true);
    try {
      const data = await fetchWithToken("/users/me", {
        method: "GET",
      });
      setUserName(data?.data?.first_name);
      setUserLastName(data?.data?.last_name); 
      setProfileimg(data?.data?.profile_picture);
      dispatch(setUser(data?.data));
      dispatch(
        setAdminStatus(data?.data?.role?.code === "admin" ? true : false)
      ); //! EDIT THIS TO admin THE REAL STATE
      // getRoleDetails(data?.data?.role._id);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setLoading(false);
    }
  };

  const getRoleDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/roles/${id}`, { method: "GET" });
      const newFlattenedPermissions = (
        Object.values(data.data.permissions).flat() as { name: string }[]
      ).map((permission) => permission.name);
      dispatch(setUserPermissions(newFlattenedPermissions));
    } catch (error) {
      console.error("Failed to fetch role:", error);
    }
  };

  // Inside the language toggle function
  const handleLanguageChange = (selectedOption) => {
    const newLanguage = currentLanguage === "en" ? "ar" : "en";
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); 
    localStorage.setItem("language", newLanguage); 
    setIsLocaleDropdownOpen(false); 
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en";
    i18n.changeLanguage(savedLanguage); 
    userdata(); 
  }, []);

  useEffect(() => {
    userdata();
  }, []);



  return (
    <div className="flex flex-col lg:px-[16px] md:flex-row items-center justify-between px-4 md:px-8 ml-auto">
      <Profile
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
      />

      <div className="flex items-center  mt-2 md:mt-0 relative ">
        <div className="relative flex items-center justify-center w-[76px] h-[40px] cursor-pointer rounded-full border-2"
          onClick={() => setIsLocaleDropdownOpen(!isLocaleDropdownOpen)} 
        >
         <span className="text-sm font-bold">
    {currentLanguage === "en" ? "EN" : "AR"}
  </span>
          <img
            src={arrowdownIcon}
            alt="arrowdownIcon"
            className=" w-4 h-4 ml-2 "
          />
           {isLocaleDropdownOpen && (
           <div className="absolute top-[60px] right-0 w-[100px] md:w-[65px] bg-[#F7F8F7] rounded-lg shadow-lg z-30">
            <button
              onClick={() => {
               handleLanguageChange(currentLanguage==="en"?"en":"ar")
              }}
              className="w-full justify-center h-[42px] rounded-lg  py-2 border-b flex items-center hover:bg-gray-200"
            >
          <span className="text-sm font-bold">
          {currentLanguage === "en" ? "AR" : "EN"}
        </span>
              
            </button>
         
     
          </div>
           )}
        </div>
        
        <img
          alt="message"
          src={meesageIcon}
          onClick={() => {
            if (!showChats) setShowNotification(false);
            setShowChats((showChats) => !showChats);
          }}
          className={`w-8 md:w-10 p-2 rounded-full
             border-2 mx-1
            ${showChats ? "bg-green-200" : ""} cursor-pointer`}
        />
        <img
          alt="notification"
          src={notificationIcon}
          onClick={() => {
            if (!showNotification) setShowChats(false);
            setShowNotification((showNotification) => !showNotification);
          }}
          className={`w-8 md:w-10 p-2 mx-1
             rounded-full
             border-2
            ${showNotification ? "bg-green-200" : ""} cursor-pointer`}
        />
        <div
          onClick={() => {
            setIsDropdown(!isDropdown);
            setShowChats(false);
            setShowNotification(false);
          }}
          className="flex items-center cursor-pointer"
        >
          <img
            alt="dp"
            src={profileimg || require("../assets/noProfile.png")}
            width={40}
            height={40}
            className="lg:w-10 lg:h-10 h-6 w-6 rounded-full"
          />
          <span className="ml-2 text-sm text-gray-800">
            {userName} {userLastName}
          </span>
          <img
            src={arrowdownIcon}
            alt="down arrow"
            className="ml-2 w-3 h-3 text-gray-600"
          />
        </div>

        {isDropdown && (
          <div className="absolute top-[60px] right-0 w-[250px] md:w-[15vw] bg-[#F7F8F7] rounded-lg shadow-lg z-30">
            <button
              onClick={() => {
                setModalVisible("me");
                setIsDropdown(false);
              }}
              className="w-full h-[42px] rounded-lg px-4 py-2 border-b flex items-center hover:bg-gray-200"
            >
              <img
                src={require("../assets/settings.png")}
                alt=""
                className="w-4 h-4 mr-2"
              />
              <div className="text-base font-normal text-gray-800">
                {t("Profile")}
              </div>
            </button>
            <button
              onClick={() => {
                setIsLogout(true);
                setIsDropdown(false);
              }}
              className="w-full h-[42px] rounded-lg px-4 py-2 border-b flex items-center hover:bg-gray-200"
            >
              <img
                src={require("../assets/logout.png")}
                alt=""
                className="w-4 h-4 mr-2"
              />
              <div className="text-base font-normal text-gray-800">
                {t("Logout")}
              </div>
            </button>
          </div>
        )}
      </div>
      <Notifications
        showNotification={showNotification}
        setShowNotification={setShowNotification}
      />
      <Chats showChats={showChats} setShowChats={setShowChats} />
      <Logout isLogout={isLogout} setIsLogout={setIsLogout} />
    </div>
  );
};

export default Header;
