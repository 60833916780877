import React from 'react'
import calendarIcon from '../../assets/calendar.svg'
import clockIcon from '../../assets/clock.svg'
import "./style.css";
import { dateToFormatedTime } from '../../utils/functions';

export interface SideComponentCard {
    title: string,
    department: string,
    date: string,
    startTime: string
}
function DashboardSideComponent(props: { title?: string, cards?: SideComponentCard[] }) {
    return (
        <>
            <div className="main-wrapper">
            <div className='main-title'>{props.title}</div>
            {props.cards?.map((card: SideComponentCard,index:number) => (
                <>
                    <div className="sideComponentCardWrapper" key={index}>
                        <div className="title-wrapper">
                            <div className="title">{card.title}</div>
                            <div className="department">({card.department})</div>
                        </div>
                        <div className="date-time-wrapper">
                            <div className="date-wrapper">
                                <div className="calendar-icon-wrapper">
                                    <img src={calendarIcon} alt={calendarIcon} />
                                </div>
                                <div className='date' >{new Date(card.date).toDateString()}</div>
                            </div>
                            <div className="time-wrapper">
                                <div className="clock-icon-wrapper">
                                    <img src={clockIcon} alt={clockIcon} />
                                </div>
                                <div className='time' >{dateToFormatedTime(card.startTime,card.date)}</div>
                            </div>
                        </div>
                    </div>
                </>
            ))}
            </div>
        </>
    )
}

export default DashboardSideComponent