import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import Leave from "../modals/leave";
import DeleteModal from "../modals/deleteModal";
import editIcon from "../../assets/cruds/editc.svg";
import deleteIcon from "../../assets/cruds/deletec.svg";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";
import Spinner from "../spinner";

interface LeaveProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

const Leaves: React.FC<LeaveProps> = ({ isModalVisible, setModalVisible }) => {
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } =
    usePagination();
  const [leaves, setLeaves] = useState<any[]>([]);
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [deleteRequestModal, setDeleteRequestModal] = useState<
    boolean | string | number
  >(false);

  const fetchLeaves = async () => {
    setIsLoading(true);
    try {
      const data = await fetchWithToken(`/leaves?page=${currentPage}`, {
        method: "GET",
      });
      setLeaves(data?.data);
      setCurrentPage(data.page);
      setTotalPages(Math.max(1, Math.ceil(data.total / data?.limit)));
      setTotalCount(data?.limit)
    setIsLoading(false);
    } catch (error) {
    setIsLoading(false);
      console.error("Failed to fetch leaves:", error);
    }
  };

  useEffect(() => {
    fetchLeaves();
  }, [currentPage, count]);

  return (
    <>
    {isLoading ? <Spinner/> : <>
      <Leave
        isModalVisible={isModalVisible}
        setIsModalVisible={setModalVisible}
        fetchLeaves={fetchLeaves}
      />
      <DeleteModal
        route="leaves"
        visibilityState={deleteRequestModal}
        setState={setDeleteRequestModal}
        fetchAllCall={fetchLeaves}
        //content={""}
      />
      {!!isLoading ? <Spinner/> : 
          <Table className="relative rounded-2xl bg-white w-full max-w-[1650px] mx-auto shadow-lg m-6">
        <TableHead className="hidden lg:table-header-group w-full">
          <TableRow className="bg-white w-full flex justify-start">
            <TableHeader className="!outline-none !border-b-0 xl:w-[200%]">
              <div className="flex items-center text-black ">
                {t("Number")}
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0 xl:w-[200%] ">
              <div className="flex items-center text-black ">
                {t("StartDate")}
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0 xl:w-[200%]">
              <div className="flex items-center text-black">
                {t("EndDate")}
              </div>
            </TableHeader>

            <TableHeader className="!outline-none !border-b-0 xl:w-[200%] ml-3">
              <div className="flex items-center text-black">
                {t("Type")}
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0 xl:w-[200%]">
              <div className="flex items-center text-black">
                {t("Status")}
              </div>
            </TableHeader>
            <TableHeader className="!outline-none !border-b-0 xl:w-[200%]"></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody className="w-full">
          {Array.isArray(leaves) &&
            leaves.map((each, index) => {
              const rowNumber = index + 1 + (currentPage - 1) * count;
              return (
                <TableRow
                  key={index}
                  className="flex flex-col lg:flex-row mb-4 border lg:border-none w-full xl:justify-between"
                >
                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Number")}
                  </TableCell>
                  <TableCell>{rowNumber}</TableCell>
                  <TableCell className="lg:hidden font-bold bg-gray-100 ">
                    {" "}
                    {t("StartDate")}
                  </TableCell>
                  <TableCell className="w-full max-w-[100px] overflow-x-auto !outline-none !border-b-0">
                    <div className="flex items-center max-w-min">
                      <div className="flex flex-col justify-center">
                        <div className="text-[16px] font-[600] mt-0">
                          {each?.start_date}
                        </div>
                      </div>
                    </div>
                  </TableCell>

                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {" "}
                    {t("EndDate")}
                  </TableCell>
                  <TableCell className="w-full max-w-[100px] overflow-x-auto !outline-none !border-b-0">
                    <div className="flex items-center max-w-min">
                      <div className="flex flex-col justify-center">
                        <div className="text-[16px] font-[600] mt-0">
                          {each?.end_date}
                        </div>
                      </div>
                    </div>
                  </TableCell>

                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {" "}
                    {t("Type")}
                  </TableCell>
                  <TableCell className="w-full max-w-[100px] overflow-x-auto !outline-none !border-b-0">
                    <div className="flex items-center max-w-min">
                      <div className="flex flex-col justify-center">
                        <div className="text-[16px] font-[600] mt-0">
                          {each?.type}
                        </div>
                      </div>
                    </div>
                  </TableCell>

                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Status")}
                  </TableCell>
                  <TableCell className="w-full max-w-[100px] overflow-x-auto !outline-none !border-b-0">
                    <div className="flex items-center max-w-min">
                      <div className="flex flex-col justify-center">
                        <div className="text-[16px] font-[600] mt-0">
                          {each?.status}
                        </div>
                      </div>
                    </div>
                  </TableCell>

                  <TableCell className="flex">
                    <div
                      onClick={() => setModalVisible(each?._id)}
                      className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                    >
                      <img alt="editIcon" src={editIcon} className="w-6 h-6" />
                    </div>
                    <div
                      onClick={() => setDeleteRequestModal(each?._id)}
                      className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                    >
                      <img
                        alt="deleteIcon"
                        src={deleteIcon}
                        className="w-6 h-6"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      }
    </>}

    </>
  );
};

export default Leaves;
