import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import DeleteModal from "../modals/deleteModal";
import DepartmentModal from "../modals/departmentModal";
import editIcon from "../../assets/cruds/editc.svg";
import deleteIcon from "../../assets/cruds/deletec.svg";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { usePagination } from "../PaginationProvider";
import { useNavigate } from "react-router-dom";
import Spinner from "../spinner";

interface Location {
  id: number;
  name: string;
  addr_one: string;
  addr_two: string;
  city: string;
  state: string;
  country: string;
  postal_code: number;
  createdAt: string;
  updatedAt: string;
}

interface Department {
  _id: number;
  name: string;
  manager_id: number;
  createdAt: string;
  updatedAt: string;
  location_id: Location;
}

interface departmentsProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
}

const Departments: React.FC<departmentsProps> = ({
  isModalVisible,
  setModalVisible,
}) => {
  const navigate = useNavigate();
  const { currentPage, setCurrentPage, setTotalPages, setTotalCount, count } =
    usePagination();
  const [content, setContent] = useState<string>("");
  const [departments, setDepartments] = useState<Department[]>([]);
  const { t, i18n } = useTranslation();

  const [deleteDepartmentModal, setDeleteDepartmentModal] = useState<
    boolean | number | string
  >(false);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const fetchDepartments = async () => {
    setIsLoading(true)
    try {
      const data = await fetchWithToken(`/departments?page=${currentPage}`, {
        method: "GET",
      });
      setDepartments(data?.data);
      setCurrentPage(data.page);
      setTotalPages(Math.max(1, Math.ceil(data.total / data?.limit)));
      setTotalCount(data?.limit);
      setIsLoading(false)

    } catch (error) {
      console.error("Failed to fetch departments:", error);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [currentPage, count]);

  return (
    <>
      {isLoading ? <Spinner/> :
      <>
          <Table className="relative rounded-2xl bg-white w-full max-w-[1650px] mx-auto shadow-lg m-6">
          <TableHead className="hidden lg:table-header-group w-full">
          <TableRow className="bg-white w-full">
            <TableHeader className="text-black">{t("Number")}</TableHeader>
            <TableHeader className="text-black">{t("Department")}</TableHeader>
            <TableHeader className="text-black">{t("Hospital")}</TableHeader>
            <TableHeader className="text-black"></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody className="w-full">
          {Array.isArray(departments) &&
            departments?.map((department, index) => {
              const rowNumber = index + 1 + (currentPage - 1) * count;
              return (
                <TableRow
                  key={department._id}
                  className="cursor-pointer hover:bg-slate-100 lg:table-row flex flex-col lg:flex-row mb-4 border lg:border-none w-full"
                >
                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Number")}
                  </TableCell>
                  <TableCell>{rowNumber}</TableCell>
                  <TableCell
                    onClick={() => navigate(`/department/${department._id}`)}
                    className="lg:hidden font-bold bg-gray-100"
                  >
                    {t("Department")}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/department/${department._id}`)}
                  >
                    {department?.name}
                  </TableCell>

                  <TableCell
                    onClick={() => navigate(`/department/${department._id}`)}
                    className="lg:hidden font-bold bg-gray-100"
                  >
                    {t("Hospital")}
                  </TableCell>
                  <TableCell
                    onClick={() => navigate(`/department/${department._id}`)}
                  >
                    {department?.location_id?.name}
                  </TableCell>

                  <TableCell className="flex justify-end  w-full">
                    <div
                      onClick={() => {
                        setModalVisible(department._id);
                      }}
                      className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                    >
                      <img alt="editIcon" src={editIcon} className="w-6 h-6" />
                    </div>
                    <div
                      onClick={() => {
                        setDeleteDepartmentModal(department._id);
                        setContent(department.name);
                      }}
                      className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                    >
                      <img
                        alt="deleteIcon"
                        src={deleteIcon}
                        className="w-6 h-6"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <DepartmentModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        fetchDepartments={fetchDepartments}
      />
      <DeleteModal
        route="departments"
        //content={content}
        visibilityState={deleteDepartmentModal}
        setState={setDeleteDepartmentModal}
        fetchAllCall={fetchDepartments}
      />
      </>
      }

    </>
  );
};

export default Departments;
