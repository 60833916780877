import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import DeleteModal from "../modals/deleteModal";
import ScheduleModal from "../modals/schedule";
import editIcon from "../../assets/cruds/editc.svg";
import deleteIcon from "../../assets/cruds/deletec.svg";
import grayArrowDown from "../../assets/grayArrowDown.png";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import SchedulesExport from "../modals/SchedulesExport";
import RotationExport from "../modals/RotationExport";
import ExportModal from "../modals/ExportModal";
import Spinner from "../spinner";

import { usePagination } from "../PaginationProvider";

interface Location {
  id: number;
  hospital_name: string;
  addr_one: string;
  addr_two: string;
  city: string;
  state: string;
  country: string;
  postal_code: number;
  createdAt: string;
  updatedAt: string;
}

interface Department {
  id: number;
  dept_name: string;
  locationId: number;
  createdAt: string;
  updatedAt: string;
  location: Location;
}

interface Position {
  id: number;
  position_name: string;
  createdAt: string;
  updatedAt: string;
}

interface Role {
  id: number;
  role: string;
  code_name: string;
  createdAt: string;
  updatedAt: string;
}

interface Shift {
  id: number;
  start_time: string;
  end_time: string;
  shift_type: string;
}

interface Schedule_dept {
  city: string;
  dept_name: string;
  deptid: number;
  hospitalname: string;
  state: string;
}

interface Schedule {
  id: number;
  date: string;
  deptId: number;
  shift: Shift;
  schedule_dept: Schedule_dept;
}

interface Schedule {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  contact: string;
  birthdate: string | null;
  profilePicture: string | null;
  userId: number;
  deptId: number;
  positionId: number;
  roleId: number;
  createdAt: string;
  updatedAt: string;
  department: Department;
  position: Position;
  role: Role;
  schedules: Schedule[];
}

interface scheduleProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  week: any;
  selectedUser: any;
  mySelectedUser: any;
}

interface User {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  department_id: number;
  position_id: number;
  role_id: number;
  contact: string | null;
  birthdate: string | null;
  profile_picture: string | null;
  resetpasswordtoken: string | null;
  resetpasswordexpires: string | null;
  createdAt: string;
  updatedAt: string;
}

const Schedules: React.FC<any> = ({
  isModalVisible,
  setModalVisible,
  week,
  selectedUser,
  mySelectedUser,
  setIsSchedulesExport,
  isSchedulesExport,
  setIsRotationExport,
  isRotationExport,
}) => {
  const [content, setContent] = useState<string>("");
  const { t, i18n } = useTranslation();
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [deleteScheduleModal, setDeleteScheduleModal] = useState<
    boolean | number | string
  >(false);
  // const [isSchedulesExport, setIsSchedulesExport] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)
  
  const { currentPage, count } = usePagination();
  const fetchSchedules = async () => {
    const firstDayFormatted = moment(week?.firstDay).format("YYYY-MM-DD");
    const lastDayFormatted = moment(week?.lastDay).format("YYYY-MM-DD");
    if (selectedUser) {
      setIsLoading(true)
      try {
        const data = await fetchWithToken(
          `/schedules?startDate=${firstDayFormatted}&endDate=${lastDayFormatted}&user_id=${selectedUser}`,
          {
            method: "GET",
          }
        );
        
        setSchedules(data?.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false)
        console.error("Failed to fetch schedules:", error);
      }
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [week, selectedUser]);

  return (
    <div className=" ">
      {isLoading ? <Spinner/> :
          <Table className="relative rounded-2xl bg-white w-full max-w-[1650px] mx-auto shadow-lg m-6">
        <TableHead className="hidden lg:table-header-group">
          <TableRow className="bg-white">
            <TableHeader className="text-black">{t("Number")}</TableHeader>
            <TableHeader className="text-black">{t("Date")}</TableHeader>
            <TableHeader className="text-black">{t("Shift")}</TableHeader>
            <TableHeader className="text-black">{t("Department")}</TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules?.length &&
            Array.isArray(schedules) &&
            schedules?.map((each, index) => {
              const rowNumber = index + 1 + (currentPage - 1) * count;
              return (
                <TableRow
                  key={each?.id}
                  className="lg:table-row flex flex-col lg:flex-row  border lg:border-none"
                >
                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Number")}
                  </TableCell>
                  <TableCell>{rowNumber}</TableCell>
                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Date")}
                  </TableCell>
                  <TableCell>
                    {moment(each?.date).format("YYYY-MM-DD")}
                  </TableCell>

                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Shift")}
                  </TableCell>
                  <TableCell>
                    {`${moment(each?.shift?.start_time, "HH:mm:ss.SSS").format(
                      "hh:mm:ss A"
                    )} - ${moment(each?.shift?.end_time, "HH:mm:ss.SSS").format(
                      "hh:mm:ss A"
                    )}`}
                  </TableCell>

                  <TableCell className="lg:hidden font-bold bg-gray-100">
                    {t("Department")}
                  </TableCell>
                  <TableCell>
                    {each?.schedule_dept?.dept_name
                      ? each?.schedule_dept?.dept_name
                      : t("No Department")}
                      
                  </TableCell>

                  <TableCell className="flex justify-end lg:w-[120px]">
                    <div
                      onClick={() => {
                        setModalVisible(each?.id);
                      }}
                      className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                    >
                      <img alt="editIcon" src={editIcon} className="w-6 h-6" />
                    </div>
                    <div
                      onClick={() => {
                        setDeleteScheduleModal(each?.id);
                        setContent(each?.date);
                      }}
                      className="w-[60px] h-full flex justify-center items-center cursor-pointer"
                    >
                      <img
                        alt="deleteIcon"
                        src={deleteIcon}
                        className="w-6 h-6"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      }
      <SchedulesExport
        isSchedulesExport={isSchedulesExport}
        setIsSchedulesExport={setIsSchedulesExport}
        fetchSchedules={fetchSchedules}
      />
      <RotationExport
        isRotationExport={isRotationExport}
        setRotationExport={setIsRotationExport}
      />
      <ScheduleModal
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        fetchSchedules={fetchSchedules}
      />
      <DeleteModal
        route="schedules"
        //content={content}
        visibilityState={deleteScheduleModal}
        setState={setDeleteScheduleModal}
        fetchAllCall={fetchSchedules}
      />
    </div>
  );
};

export default memo(Schedules);
