import { memo, useState } from "react";
import RotationTable from "../../components/rotationTable";

const Rotation = () => {
  const [refresh, setRefresh] = useState(false);
  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };
  return (
    <div className="w-full h-full flex flex-1 gap-[24px] xl:overflow-hidden overflow-scroll xl:flex-row flex-col">
      <div className="flex flex-col min-w-[50%] max-w-full w-full">
        <RotationTable refresh={refresh} handleRefresh={handleRefresh} />
      </div>
    </div>
  );
};

export default memo(Rotation);
