import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import fetchWithToken from '../../utils/api';
import UpComingShifts from '../upComingShifts';
import DashboardSideComponent, { SideComponentCard } from '../dashboardSideComponent/dashboardSideComponent';
import { title } from 'process';
import SwapRequests from '../SwapRequests';

function DashboardSideWrapperComponent() {
    const [shiftsCards, setShiftsCards] = useState<SideComponentCard[]>([]);
    const [isUpcomingShiftsLoading , setIsupcomingShiftsLoading ] = useState<boolean>(false)
    const { t, i18n } = useTranslation();
    const [swapRequestsCards, setSwapRequestsCards] = useState<SideComponentCard[]>([]);
    
    const [isLoading, setIsLoading] = useState(false)

    const fetchUpComingShifts = async () => {
      setIsupcomingShiftsLoading(true);
      try {
        const data = await fetchWithToken("/schedules/upcoming", {
          method: "GET",
        });
        setShiftsCards(mapShiftsCards(data.data))
        console.log(data.data);
      setIsupcomingShiftsLoading(false);
  
      } catch (error) {
        console.error (t("Failed to fetch swap requests:"), error);
      setIsupcomingShiftsLoading(false);
  
      }
    };
    const fetchSwapRequests = async () => {
        setIsLoading(true);
        try {
          const data = await fetchWithToken("/swap-requests?status=pending", {
            method: "GET",
          });
        //   setSwapRequestsCards(mapSwapRequestsCards(data?.data?.from_user))
          setIsLoading(false);
        } catch (error) {
          console.error("Failed to fetch swap requests:", error);
          setIsLoading(false);
        }
      };
//   const mapSwapRequestsCards = (swapRequests:SwapRequests[]):SideComponentCard[]=>{
//       return swapRequests.map((item:SwapRequests)=>{
//           return{
//               title:`${item?.from_user?.first_name} ${item?.from_user?.last_name}`.
//               date:item?.date,
//               department:item?.department?.name
//           }
//       })
//   }
  const mapShiftsCards = (UpComingShifts:UpComingShifts[]):SideComponentCard[]=>{
      return UpComingShifts.map((item:UpComingShifts)=>{
          return{
              title:item?.shift?.shift_type,
              date:item?.date,
              department:item?.department?.name,
              startTime:item.shift.start_time
          }
      })
  }
    useEffect(() => {
      fetchUpComingShifts();
      fetchSwapRequests()
    }, []);
    return (
    <>
    <DashboardSideComponent
    title={t("UpcomingShift")}
    cards={shiftsCards}
    />
    {/* <DashboardSideComponent
    title={t("RequestASwap")}
    cards={swapRequestsCards}
    /> */}
    </>
  )
}

export default DashboardSideWrapperComponent