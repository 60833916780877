import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import calenderDark from "../../assets/calenderDark.png";
import "./style.css";
import { WeekPicker } from "../weekpicker";
import moment from "moment";
import SwapRequests from "../SwapRequests";
import calender from "../../assets/calender.png";
import requestBtn from "../../assets/requestBtn.png";
import clock from "../../assets/clock.png";
import stethoscope from "../../assets/stethoscope.png";
import locationimg from "../../assets/location.png";
import reqMsg from "../../assets/reqMsg.png";
import AddNoteModal from "../modals/AddNoteModal";
import SuccessToast from "../modals/successToast";
import FailToast from "../modals/failToast";
import Leave from "../modals/leave";
import fetchWithToken from "../../utils/api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Select } from "@headlessui/react";
import ShiftDetailsForSwap from "../modals/shiftDetailsForSwap";
import Spinner from "../spinner";

const colors = [
  "#c0b0ff",
  "#a5eebe",
  "#fcee71",
  "#f6abd8",
  "#f28585",
  "#fdd484",
  "#b8d9ff",
];
interface ScheduleTableProps {
  refresh: boolean;
  handleRefresh: () => void;
}

const ScheduleTable: React.FC<ScheduleTableProps> = ({
  refresh,
  handleRefresh,
}) => {
  const SelectedSchedule = useSelector(
    (state: any) => state?.user?.firstSchedule
  );
  const userDepartment = useSelector(
    (state: any) => state.user.user.department
  );
  const currentUserCode = useSelector(
    (state: any) => state.user?.user?.role?.code
  );
  const [shiftDetailsModal, setShiftDetailsModal] = useState<
    boolean | string | number
  >(false);
  const [selectedSwapRequest, setSelectedSwapRequest] = useState();
  const [action, setAction] = useState<boolean>(false);
  const [week, setWeek] = useState<any>();
  const [isAddNoteModalVisible, setAddNoteModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t, i18n } = useTranslation();
  const [originalSchedules, setOriginalSchedules] = useState();
  const [swapRequests, setSwapRequests] = useState<any>([]);
  const [loaderHandler, setLoaderHandler] = useState<{[id:string]:boolean}>({departments:false,schedule:false,swapRequests:false});
  const [activeTab, setActiveTab] = useState<any>(0);
  const [schedule, setSchedule] = useState<any>();
  const [departments, setDepartments] = useState([]);
  const [activeDepartment, setActiveDepatment] = useState(userDepartment?._id);
  const firstDay = moment(week?.firstDay);
  const lastDay = moment(week?.lastDay);
  const daysOfWeek = [
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
    t("Sunday"),
  ];
  const weekDates = daysOfWeek.map((_, index) =>
    firstDay.clone().add(index, "days")
  );

  let displayMonth;
  if (firstDay.isSame(lastDay, "month")) {
    // Same month
    displayMonth = firstDay.format("MMMM YYYY");
  } else if (firstDay.isSame(lastDay, "year")) {
    // Different months, same year
    displayMonth = `${firstDay.format("MMMM")} - ${lastDay.format(
      "MMMM YYYY"
    )}`;
  } else {
    // Different months and different years
    displayMonth = `${firstDay.format("MMMM YYYY")} - ${lastDay.format(
      "MMMM YYYY"
    )}`;
  }

  const fetchSchedule = async (dateFrom: string, dateTo: string) => {
    const formattedDateFrom = moment(dateFrom).format("YYYY-MM-DD");
    const formattedDateTo = moment(dateTo).format("YYYY-MM-DD");
    if(!activeDepartment) return
    setLoaderHandler({...loaderHandler,schedule:true})
    try {
      const data = await fetchWithToken(
        `/schedules/department?startDate=${formattedDateFrom}&endDate=${formattedDateTo}&department_id=${
          activeDepartment ? activeDepartment : userDepartment._id
        }`,
        {
          method: "GET",
        }
      );
      setOriginalSchedules(data?.data);
      const groupedByUser = data?.data.reduce((acc, schedule) => {
        const { user } = schedule;
        const userId = user.id;

        if (!acc[userId]) {
          acc[userId] = {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            contact: user.contact,
            birthdate: user.birthdate,
            schedules: [],
          };
        }

        acc[userId].schedules.push({
          id: schedule.id,
          date: schedule.date,
          shift_type: schedule.shift.shift_type,
          start_time: schedule.shift.start_time,
          end_time: schedule.shift.end_time,
        });

        return acc;
      }, {});

      const combinedSchedules = Object.values(groupedByUser);
      setSchedule(combinedSchedules || []);
    setLoaderHandler({...loaderHandler,schedule:false})

    } catch (error) {
      console.error(t("Failed to fetch locations:"), error);
    setLoaderHandler({...loaderHandler,schedule:false})
    }
  };

  const fetchDepartments = async () => {
    setLoaderHandler({...loaderHandler,departments:false})
    try {
      const data = await fetchWithToken("/departments", {
        method: "GET",
      });
      setDepartments(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.name,
        }))
      );
    setLoaderHandler({...loaderHandler,departments:false})
    } catch (error) {
      console.error("Failed to fetch departments:", error);
    setLoaderHandler({...loaderHandler,departments:false})
    }
  };

  useEffect(() => {
    setActiveDepatment(userDepartment?._id);
  }, [userDepartment]);

  useEffect(() => {
    fetchSchedule(
      moment(week?.firstDay).format("YYYY-MM-DD"),
      moment(week?.lastDay).format("YYYY-MM-DD")
    );
  }, [activeDepartment]);

  useEffect(() => {
    if (!week?.firstDay || !week?.lastDay) return;
    fetchSchedule(
      moment(week?.firstDay).format("YYYY-MM-DD"),
      moment(week?.lastDay).format("YYYY-MM-DD")
    );
    fetchDepartments();
  }, [week]);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const fetchSwapRequests = async () => {
    setLoaderHandler({...loaderHandler,swapRequests:true})
    try {
      const data = await fetchWithToken("/swap-requests?status=pending", {
        method: "GET",
      });
      setSwapRequests(data?.data?.from_user);
      setLoaderHandler({...loaderHandler,swapRequests:false})

    } catch (error) {
      console.error("Failed to fetch swap requests:", error);
    setLoaderHandler({...loaderHandler,swapRequests:false})

    }
  };

  useEffect(() => {
    fetchSwapRequests();
  }, [refresh]);

  const handleAccept = async (each: SwapRequests) => {
    try {
      const response = await fetchWithToken(
        `/swapRequests/acceptswap/${each?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        handleRefresh();
      }
      // SuccessToast(
      //   "Accepted",
      //   `You accepted request from ${each?.first_name_from} ${each?.last_name_from}`
      // );
    } catch (error) {
      // FailToast("Accept swap failed. Please try again.");
      console.error("Accept swap failed :", error);
    }
  };
  //swap details time difference
  const formatTimeDifference = (createdDateTime: string) => {
    const createdMoment = moment(createdDateTime);
    const now = moment();
    //return now.diff(createdMoment, "minutes") + " " + t("min ago")
    const totalMinutes = now.diff(createdMoment, "minutes");
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const days = Math.floor(hours / 24);

    if (days === 1) {
      return t("days_ago_arabic_one", { count: days }); // For 1 day
    } else if (days === 2) {
      return t("days_ago_arabic_two", { count: days }); // For 2 days
    } else if (days >= 3 && days <= 10) {
      return t("days_ago_arabic_few", { count: days }); // For 3 to 10 days
    } else if (days >= 11) {
      return t("days_ago_arabic_many", { count: days }); // For 11 and above
    } else if (hours >= 1) {
      return t("hours_minutes_ago", { hours, minutes });
    } else if (minutes > 0) {
      return t("minutes_ago", { minutes });
    } else {
      return t("now");
    }
  };

  const handleShowNotes = (data: any, action) => {
    setAction(action);
    setAddNoteModalVisible(true);
    setSelectedSwapRequest(data);
  };

  return (
    <div className="h-full xl:ml-[16px]">
      <AddNoteModal
        approved={action}
        data={selectedSwapRequest}
        isModalVisible={isAddNoteModalVisible}
        setModalVisible={setAddNoteModalVisible}
        handleRefresh={handleRefresh}
      />
      <Leave
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        fetchLeaves=""
      />
      <ShiftDetailsForSwap
        isModalVisible={shiftDetailsModal}
        setModalVisible={setShiftDetailsModal}
        schedules={originalSchedules}
      />
      <div className="flex items-center xl:my-2 xl:h-[40px] h-auto w-full justify-center align-middle ">
        <div className="flex xl:flex-row flex-col xl:justify-around justify-center align-middle xl:w-full h-full ">
          <p className="text-[20px] font-[700] xl:mr-[5%] mt-1 xl:w-[100px] text-nowrap text-center">
            {t("AvailableShifts")}
          </p>
          <div className="xl:mt-0 mt-1">
            <WeekPicker onChange={(e: any) => setWeek(e)} />
          </div>
          <div className="flex items-center xl:ml-[24px] h-[45px] rounded-[4px] px-[10px] xl:mt-0 mt-[10px] xl:mb-0 mb-[10px]  bg-[#f7f8f7]">
            <div
              className={`xl:w-[80px] w-[50%] mr-[8px] rounded-[4px] p-1 flex justify-center cursor-pointer ${
                activeTab === 0
                  ? " bg-[#00A843] hover:bg-[#389365] text-white "
                  : "bg-[#f7f8f7] text-black"
              }`}
              onClick={() => handleTabClick(0)}
            >
              {t("CalenderButton")}
            </div>
            <div
              className={`xl:w-[80px] w-[50%] rounded-[4px] p-1 flex justify-center cursor-pointer ${
                activeTab === 1
                  ? " bg-[#00A843] hover:bg-[#389365] text-white"
                  : "bg-[#f7f8f7] text-black"
              }`}
              onClick={() => handleTabClick(1)}
            >
              {t("GridButton")}
            </div>
          </div>
          {currentUserCode === "admin" && (
            <div className="mb-2 xl:mb-0 ">
              <Select
                className="xl:w-[110px] w-[250px] h-[40px] border border-[#7E8581] rounded-md ml-2"
                name="deptId"
                onChange={(e: any) => setActiveDepatment(e.target.value)}
                value={activeDepartment}
              >
                {loaderHandler.departments ? <> <Spinner/> </> : departments?.map((dep: any) => (
                  <option key={dep?._id} value={dep?._id}>
                    {dep?.name}
                  </option>
                ))}
              </Select>
            </div>
          )}
          <div className="ml-[1%]">
            <button
              onClick={() => setIsModalVisible(true)}
              className="w-[170px]  h-[40px] rounded-md bg-[#00A843] hover:bg-[#389365] text-[#F8FAF8] text-[16px] px-[24px] py-[8px] mr-2"
            >
              {t("leaveRequestB")}
              <span className="ml-[4px]">+</span>
            </button>
          </div>
        </div>
      </div>
      {activeTab === 0 && (
        <div className="xl:overflow-y-scroll overflow-scroll xl:mt-0 mt-2 scrollbar-hidden h-[90%]">
          <Table className={"w-[100%]"}>
            <TableHead>
              <TableRow>
                <TableHeader
                  style={{
                    border: 0,
                    padding: 0,
                  }}
                  className="xl:w-[100px] xl:min-w-[100px] xl:max-w-[100px] w-[20px] min-w-[20px] max-w-[20px]"
                >
                  <div className="flex justify-center">
                    <img
                      alt="calenderDark"
                      src={calenderDark}
                      className="xl:w-[20px] w-[10px]"
                    />
                  </div>
                </TableHeader>
                {weekDates?.map((date, index) => (
                  <TableHeader
                    key={daysOfWeek[index] + index}
                    className="w-[120px]"
                    style={{
                      border: 0,
                      padding: 5,
                    }}
                  >
                    <div className="xl:w-full w-[40px] text-[black] xl:h-[70px] h-[30px] flex flex-col items-center justify-center rounded-[4px] bg-[#e8ebe9]">
                      <div className="xl:text-[16px] text-[7px] font-[600]">
                        {daysOfWeek[index]}
                      </div>
                      <div className="hidden xl:flex text-[24px] font-[500] mt-1">
                        {date.format("DD")}
                      </div>
                    </div>
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="cursor-pointer">
              {!!loaderHandler.schedule ? <> <Spinner/> </> : schedule?.map((each: any, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{ border: 0, padding: 0 }}
                    className={"bg-[#f7f8f7] font-[600] text-[12px]"}
                  >
                    <div className="h-[50px] pl-3 w-[100px] flex flex-wrap items-center">
                      <div className="mr-1">{each?.first_name}</div>
                      <div className="mr-1">{each?.last_name}</div>
                    </div>
                  </TableCell>
                  {weekDates?.map((val, index) => (
                    <TableCell
                      key={index}
                      style={{
                        border: 0,
                        padding: 0,
                      }}
                    >
                      {each?.schedules?.filter(
                        (schdl: any) =>
                          moment(schdl?.date)?.format("DD") ===
                          val?.format("DD")
                      )?.length ? (
                        each?.schedules
                          ?.filter(
                            (schdl: any) =>
                              moment(schdl?.date)?.format("DD") ===
                              val?.format("DD")
                          )
                          ?.map((schdl: any) => (
                            <div
                              key={schdl?.id}
                              className="flex items-center justify-center"
                              onClick={() => {
                                setShiftDetailsModal(schdl ?? false);
                              }}
                            >
                              <div
                                style={{
                                  background: colors[index],
                                  opacity:
                                    val?.diff(moment(), "d") < 0 ? 0.3 : 0.9,
                                  border:
                                    schdl?.id === SelectedSchedule?.id
                                      ? "2px solid #00a843"
                                      : "",
                                }}
                                className={` rounded-[3px] w-full h-[60px] italic flex flex-col justify-center pl-3 m-1 relative`}
                              >
                                <div className="font-[600] text-[14px]">
                                  {userDepartment?.name}
                                </div>
                                <div className="text-[12px] -mt-1">{`${moment(
                                  schdl?.date,
                                  "HH:mm:ss"
                                ).format("ha")} - ${moment(
                                  schdl?.date,
                                  "HH:mm:ss"
                                ).format("ha")}`}</div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className="flex items-center justify-center w-full">
                          <div
                            className={`bg-[#f7f8f7] rounded-[3px] w-full h-[60px] italic flex flex-col justify-center pl-3 m-1 relative`}
                          />
                        </div>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {activeTab === 1 && (
        <div className="overflow-scroll mt-6 scrollbar-hidden max-h-[85%]">
          <div className="grid xl:grid-cols-2 grid-cols-1 gap-[24px] xl:mx-[24px] xl:mr-0 mr-[5%]">
            {!!loaderHandler.swapRequests ? <> <Spinner/> </> : Array.isArray(swapRequests) &&
              swapRequests?.map((each, index) => (
                <div
                  className="bg-[#f7f8f7] xl:overflow-hidden rounded-[8px] w-full max-h-[250px] flex flex-col justify-between  p-3 py-4"
                  key={each?.id}
                >
                  <div className="w-full flex items-center align-middle justify-between mb-4">
                    <div className="flex items-center">
                      <div className="xl:text-[18px] text-[13px] font-[700]">{`${each?.from_user?.first_name} ${each?.from_user?.last_name}`}</div>
                      <div className="xl:text-[14px] text-[12px] font-[400] ml-2 text-[#5d6561]">
                        {index % 2 === 0
                          ? t("requested a day off")
                          : t("Requested a Shift Swap")}
                      </div>
                    </div>
                    <div className="xl:text-[14px] text-[12px] font-[600]">
                      {formatTimeDifference(each?.createdAt)}
                    </div>
                  </div>

                  <div className="flex justify-between align-middle mb-3">
                    <div className="h-[50px] w-[160px] flex flex-col justify-between">
                      <div className="text-[12px] text-[#5d6561]">
                        {t("From")}
                      </div>
                      <div className="text-[12px] flex items-center w-full">
                        <img
                          alt="calender"
                          src={calender}
                          className="w-[13px]"
                        />
                        <div className="xl:text-[16px] text-[12px] ml-3">
                          {moment(each?.from_schedule?.date).format(
                            "ddd, DD MMM YYYY"
                          )}
                        </div>
                      </div>
                      <div className="w-[170px] flex flex-col justify-between">
                        <div className="xl:text-[12px] text-[10px] flex items-center w-full">
                          <img alt="clock" src={clock} className="w-[13px]" />
                          <div className="xl:text-[16px] text-[12px] ml-3">
                            {moment(
                              each?.from_schedule?.shift?.start_time,
                              "HH:mm:ss"
                            ).format("h:mm A")}{" "}
                            -{" "}
                            {moment(
                              each?.from_schedule?.shift?.end_time,
                              "HH:mm:ss"
                            ).format("h:mm A")}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    {each?.to_schedule && (
                      <div className="w-[60px] h-[60px] text-[#00a843] text-[16px] font-[700] flex items-center justify-center cursor-pointer">
                        <img
                          alt="requestBtn"
                          src={requestBtn}
                          className="w-[16px]"
                        />
                      </div>
                    )}
                    <div className="h-[50px] w-[160px] flex flex-col justify-between">
                      {each?.to_schedule && (
                        <>
                          <div className="text-[12px] text-[#5d6561]">
                            {t("To")}
                          </div>
                          <div className="text-[12px] flex items-center w-full">
                            <img
                              alt="calender"
                              src={calender}
                              className="w-[13px]"
                            />
                            <div className="xl:text-[16px] text-[12px] ml-3">
                              {moment(each?.to_schedule?.date).format(
                                "ddd, DD MMM YYYY"
                              )}
                            </div>
                          </div>
                          <div className="xl:w-[170px] w-[150px] flex flex-col justify-between">
                            <div className="text-[12px] flex items-center w-full">
                              <img
                                alt="clock"
                                src={clock}
                                className="w-[13px]"
                              />
                              <div className="xl:text-[16px] text-[12px] xl:text-nowrap text-wrap ml-3">
                                {moment(
                                  each?.to_schedule?.shift?.start_time,
                                  "HH:mm:ss"
                                ).format("h:mm A")}{" "}
                                -{" "}
                                {moment(
                                  each?.to_schedule?.shift?.end_time,
                                  "HH:mm:ss"
                                ).format("h:mm A")}{" "}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <img
                      src={stethoscope}
                      alt=""
                      className="w-[13px] h-[13px] my-auto"
                    />
                    <div className="text-base text-[#333937] ml-3">
                      {each?.to_user?.department?.dept_name ||
                        t("No Department")}
                    </div>
                  </div>
                  <div className="flex">
                    <img
                      src={locationimg}
                      alt=""
                      className="w-[13px] h-[13px] my-auto"
                    />
                    <div className="text-base text-[#333937] ml-3">
                      {each?.to_user?.Location || t("No Location")}
                      {/* work on location */}
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-center gap-[16px] mt-5">
                    <div
                      onClick={() => handleShowNotes(each, true)}
                      className="w-[150px] h-[40px] rounded-[8px] bg-[#00A843] hover:bg-[#389365] text-[#fff] text-[16px] font-[700] flex items-center justify-center cursor-pointer"
                    >
                      {t("AcceptSwap")}
                    </div>
                    <div
                      onClick={() => handleShowNotes(each, false)}
                      className="xl:w-[150px] w-[115px] h-[40px] rounded-[8px] border border-1 border-[#E23121] text-[#E23121]  text-[16px] font-[700] flex items-center justify-center cursor-pointer"
                    >
                      {t("Decline")}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduleTable;
