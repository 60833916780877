import React from "react";
import RightArrow from "../assets/right_arrowF.svg"; 
import LeftArrow from "../assets/left_arrow_F.svg"; 
import { usePagination } from "./PaginationProvider";

const TableFooter = () => {
  const { currentPage, totalPages, setCurrentPage } = usePagination();

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="flex justify-center w-full gap-3">
      <button
        className={`w-[40px] h-40px] rounded-[62px] border flex items-center justify-center 
          ${currentPage > 1
            ? "border-[#121D57] text-[#121D57] hover:bg-[#E0E5F2]"
            : "border-[#DAE7F1] text-gray-400 cursor-not-allowed"}`}
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
      >
        <img
          src={LeftArrow}
          alt="left arrow"
          className="w-[10px] h-[10px] transition-all"
        />
      </button>

      {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
        <button
          key={page}
          className={`w-[40px] h-[40px] rounded-[62px] border border-[#DAE7F1] flex items-center justify-center 
            ${currentPage === page
              ? "bg-[#2F3E8B] text-white"
              : "bg-white text-[#121D57] hover:bg-[#E0E5F2]"}`}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      ))}

      <button
        className={`w-[40px] h-[40px] rounded-[62px] border flex items-center justify-center 
          ${currentPage < totalPages
            ? "border-[#121D57] text-[#121D57] hover:bg-[#E0E5F2]"
            : "border-[#DAE7F1] text-gray-400 cursor-not-allowed"}`}
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        <img
          src={RightArrow}
          alt="right arrow"
          className="w-[10px] h-[10px] transition-all"
        />
      </button>
    </div>
  );
};

export default TableFooter;
