import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select, { SingleValue } from "react-select";
import moment from "moment";
import SuccessToast from "./successToast";
import FailToast from "./failToast";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { updateUserProfile } from "../../utils/token";
import { profile } from "console";
import { useSelector } from "react-redux";

interface ProfileProps {
  isModalVisible: any;
  setModalVisible: any;
  fetchProfiles?: any;
}

const Profile: React.FC<ProfileProps> = ({
  isModalVisible,
  setModalVisible,
  fetchProfiles,
}) => {
  const isAdd = isModalVisible === true;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const userRole = useSelector((state: any) => state?.user?.user?.role);
  const [userdata, setUserdata] = useState<any | null | undefined>(null);
  const [departments, setDepartments] = useState<any>([]);
  const [levels, setLevels] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [positions, setPositions] = useState<any>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isDecline, setIsDecline] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const [image, setImage] = useState<string>("");

  const formik = useFormik<{
    user_name: string | undefined;
    first_name: string | undefined;
    last_name: string | undefined;
    password: string | undefined;
    birthdate: string;
    contact: string | undefined;
    email: string;
    deptId: any | null;
    level: any | null;
    positionId: any | null | undefined;
    roleId: any | null | undefined;
    profile_picture: string | null;
    work_contact?: string | null;
  }>({
    initialValues: {
      user_name: userdata?.username,
      password: userdata?.password,
      first_name: userdata?.first_name,
      last_name: userdata?.last_name,
      birthdate: userdata?.birthdate
        ? moment(userdata?.birthdate).format("YYYY-MM-DD")
        : "",
      email: userdata?.email ?? "",
      deptId: departments?.filter((each) => each?._id === userdata?.department?._id)[0],
      level: levels?.filter((each) => each?._id === userdata?.level?._id)[0],
      positionId: positions?.filter((each) => each?.id === userdata?.position?._id)[0],
      contact: userdata?.contact,
      roleId: roles?.filter((each) => each?.code === userdata?.role)[0],
      profile_picture: userdata?.profile_picture || "",
      work_contact: userdata?.work_contact || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      user_name: Yup.string().required(t("Required")),
      password: isAdd ? Yup.string().required(t("Required")) : Yup.string(),
      first_name: Yup.string().required(t("Required")),
      last_name: Yup.string().required(t("Required")),
      birthdate: Yup.string().required(t("Required")),
      email: Yup.string().email(t("EmailInvalid")).required(t("Required")),
      deptId: Yup.object({ value: Yup.string().required(t("RequiredValidation")) }),
      positionId: Yup.object({ value: Yup.string().required(t("RequiredValidation")) }),
      roleId: Yup.object({ value: Yup.string().required(t("RequiredValidation")) }),
    }),
    onSubmit: async (values) => {
      try {
        const body: {
          email: string;
          username?: string;
          first_name?: string;
          last_name?: string;
          department?: any;
          position?: any;
          level?: any;
          role?: any;
          contact?: string;
          birthdate: string;
          password?: string;
          profile_picture?: string | null;
          work_contact?: string | null;
        } = {
          email: values?.email,
          username: values?.user_name,
          first_name: values?.first_name,
          last_name: values?.last_name,
          department: values?.deptId?._id,
          position: values?.positionId?.id,
          level: values?.level?._id,
          role: values?.roleId?.code,
          contact: values?.contact,
          work_contact: values?.work_contact,
          birthdate: moment(values?.birthdate).toISOString(),
        };

        if (isAdd) {
          body.password = values?.password;
        }

        if (image || userdata?.profile_picture) {
          body.profile_picture = image || userdata?.profile_picture;
        }

        const response = await fetchWithToken(
          isAdd ? `/users` : `/users/${userdata?._id}`,
          {
            method: isAdd ? "POST" : "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
          }
        );
        setModalVisible(!isModalVisible);
        updateUserProfile(response);
        if (typeof fetchProfiles === "function") fetchProfiles();
        else console.warn("fetchProfiles is not a function");
        SuccessToast("Success", `User profile ${isAdd ? "created" : "updated"} successfully`, t);
      } catch (error: any) {
        console.error("Error details:", error);
        if (error?.message?.toLowerCase().includes("username or email already exists")) {
          FailToast(t("Username or email already exists"), t);
        } else {
          FailToast(t("Update profile failed. Please try again."), t);
        }
      }
    },
  });

  const handleImageUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("name", "Profile Photo");
      formData.append("file", file);
      const data = await fetchWithToken("/upload", { method: "POST", body: formData });
      const uploadedFileUrl = data?.file;
      if (typeof uploadedFileUrl === "string") {
        setImage(uploadedFileUrl);
        SuccessToast("Success", "Image uploaded successfully", t);
      } else {
        console.error("Uploaded file URL is not a string:", uploadedFileUrl);
        FailToast(t("Image upload failed. Please try again."), t);
      }
    } catch (error) {
      console.error("Failed to upload image:", error);
      FailToast(t("Image upload failed. Please try again."), t);
    }
  };

  const fetchDepartments = async () => {
    try {
      const data = await fetchWithToken("/departments", { method: "GET" });
      setDepartments(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch departments:", error);
    }
  };

  const fetchPositions = async () => {
    try {
      const data = await fetchWithToken("/positions", { method: "GET" });
      setPositions(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?.id,
          label: each?.name,
          levels: each?.levels,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch positions:", error);
    }
  };

  const fetchRoles = async () => {
    try {
      const data = await fetchWithToken("/roles", { method: "GET" });
      setRoles(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  };

  const fetchCurrentUser = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/users/${id}`, { method: "GET" });
      setUserdata(data?.data);
    } catch (error) {
      console.error("Failed to fetch user: ", error);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      fetchDepartments();
      fetchPositions();
      fetchRoles();
      if (typeof isModalVisible === "number" || typeof isModalVisible === "string") {
        fetchCurrentUser(isModalVisible);
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    const matchingPosition = positions.find(
      (position) => position.id === userdata?.position?._id
    );
    if (matchingPosition) {
      setLevels(
        matchingPosition.levels.map((item) => ({
          ...item,
          value: item?._id,
          label: item?.name,
        }))
      );
    } else {
      setLevels([]);
    }
  }, [userdata, positions]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Esc") {
        setModalVisible(!isModalVisible);
      }
    };

    if (isModalVisible) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalVisible, setModalVisible]);

  return (
    isModalVisible && (
      <main
        className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[1] p-4"
      >
        <div
          className="bg-white rounded-lg w-full max-w-md max-h-[90vh] overflow-auto flex flex-col items-center"
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="relative w-full mb-5">
            <div className="text-center text-lg font-bold">{t("Profile")}</div>
            <img
              alt="close"
              src={require("../../assets/close.png")}
              onClick={() => setModalVisible(!isModalVisible)}
              className="absolute right-4 top-1 w-[20px] cursor-pointer"
            />
          </div>
          {userdata?._id && (
            <div className="relative flex items-center justify-center">
              <img
              
                src={
                  image
                    ? `${image}?timestamp=${new Date().getTime()}`
                    : userdata?.profile_picture || require("../../assets/noProfile.png")
                }
                alt="profile"
                className="w-[87px] h-[87px] rounded-full"
                width={90}
                height={90}
                onError={(e) => {
                  e.currentTarget.src = require("../../assets/noProfile.png");
                }}
              />
              <div
                className="w-[40px] h-[40px] absolute bg-[#F8FAF8] rounded-[4px] -bottom-2 -right-2"
                onClick={() => fileInputRef?.current?.click()}
              >
                <img
                  src={require("../../assets/editProfile.png")}
                  alt="edit"
                  className="p-2"
                />
                <input
                  ref={fileInputRef}
                  className="hidden"
                  type="file"
                  onChange={(e: any) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      handleImageUpload(file);
                    }
                  }}
                />

              </div>
            </div>
          )}
          <div className="text-sm text-[#101010] w-full px-5">
            <div className="font-bold">{t("User Name")}</div>
            <input
              type="text"
              placeholder={t("User Name")}
              name="user_name"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.user_name}
              style={{
                borderColor:
                  formik?.touched?.user_name && formik?.errors?.user_name
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.first_name && formik?.errors?.first_name && (
                <div>{formik?.errors?.first_name}</div>
              )}
            </div>
            <div className="font-bold">{t("FName")}</div>
            <input
              type="text"
              placeholder={t("FNameTitle")}
              name="first_name"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.first_name}
              style={{
                borderColor:
                  formik?.touched?.first_name && formik?.errors?.first_name
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.first_name && formik?.errors?.first_name && (
                <div>{formik?.errors?.first_name}</div>
              )}
            </div>
            <div className="font-bold">{t("LName")}</div>
            <input
              type="text"
              placeholder={t("LNameTitle")}
              name="last_name"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
              id="last_name"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.last_name}
              style={{
                borderColor:
                  formik?.touched?.last_name && formik?.errors?.last_name
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.last_name && formik?.errors?.last_name && (
                <div>{formik?.errors?.last_name}</div>
              )}
            </div>
            {
              isAdd && (
                <>
                  <div className="font-bold">{t("PasswordLabel")}</div>
                  <input
                    type="password"
                    placeholder={t("PasswordLabel")}
                    name="password"
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.password}
                    style={{
                      borderColor:
                        formik?.touched?.password && formik?.errors?.password
                          ? "#E23121"
                          : "#5D6561",
                    }}
                  />
                  <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
                    {formik?.touched?.password && formik?.errors?.password && (
                      <div>{formik?.errors?.password}</div>
                    )}
                  </div>
                </>
              )
            }

            <div className="font-bold">{t("Contact")}</div>
            <input
              type="text"
              placeholder={t("ContactTitle")}
              name="contact"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
              id="contact"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.contact}
              style={{
                borderColor:
                  formik?.touched?.contact && formik?.errors?.contact
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.contact && formik?.errors?.contact && (
                <div>{formik?.errors?.contact}</div>
              )}
            </div>
            <div className="font-bold">{t("BrithDate")}</div>
            <input
              type="date"
              placeholder={t("BrithDateTitle")}
              name="birthdate"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
              id="birthdate"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.birthdate}
              style={{
                borderColor:
                  formik?.touched?.birthdate && formik?.errors?.birthdate
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.birthdate && formik?.errors?.birthdate && (
                <div>{formik?.errors?.birthdate}</div>
              )}
            </div>
            <div className="font-bold">{t("Email")}</div>
            <input
              type="email"
              placeholder={t("EmailTitle")}
              name="email"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
              id="email"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.email}
              style={{
                borderColor:
                  formik?.touched?.email && formik?.errors?.email
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.email && formik?.errors?.email && (
                <div>{formik?.errors?.email}</div>
              )}
            </div>
            <div className="font-bold mb-[8px]">{t("Department")}</div>
            <Select
              placeholder={t("Select")}
              options={departments}
              value={formik.values.deptId}
              name="deptId"
              onChange={(option) => formik.setFieldValue("deptId", option)}
              onBlur={formik.handleBlur}
              isDisabled={userRole === "user"}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {/* {formik?.touched?.deptId && formik?.errors?.deptId && (
                <div>{formik?.errors?.deptId}</div>
              )} */}
            </div>
            <div className="font-bold mb-[8px]">{t("Position")}</div>
            <Select
              placeholder={t("Select")}
              options={positions}
              isDisabled={userRole === "user"}
              value={formik.values.positionId}
              name="positionId"
              onChange={(option) => {
                formik.setFieldValue("positionId", option);
                const selectedPosition = positions.find((pos: any) => pos.value === option?.value);
                setLevels(selectedPosition.levels.map((item) => ({
                  ...item,
                  value: item?._id,
                  label: item?.name,
                })));

              }}
              onBlur={formik.handleBlur}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {/* {formik?.touched?.positionId && formik?.errors?.positionId && (
                <div>{formik?.errors?.positionId}</div>
              )} */}
            </div>
            <div className="font-bold mb-[8px]">{t("Level")}</div>
            <Select
              placeholder={t("Select")}
              options={levels}
              value={formik.values.level}
              name="level"
              onChange={(option) => formik.setFieldValue("level", option)}
              onBlur={formik.handleBlur}
              isDisabled={userRole === "user"}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {/* {formik?.touched?.level && formik?.errors?.level && (
    <div>{formik?.errors?.level}</div>
  )} */}
            </div>
            <div className="font-bold">{t("employe id ")}</div>
            <input
              type="text"
              placeholder={t("employe id")}
              name="work_contact"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.work_contact ?? ""}
              style={{
                borderColor:
                  formik?.touched?.work_contact && formik?.errors?.work_contact
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {formik?.touched?.work_contact && formik?.errors?.work_contact && (
                <div>{formik?.errors?.work_contact}</div>
              )}
            </div>
            <div className="font-bold">{t("Role")}</div>
            <Select
              placeholder={t("Select")}
              options={roles}
              value={formik.values.roleId}
              name="roleId"
              onChange={(option) => formik.setFieldValue("roleId", option)}
              onBlur={formik.handleBlur}
              isDisabled={userRole === "user"}

            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[14px]">
              {/* {formik?.touched?.roleId && formik?.errors?.roleId && (
                <div>{formik?.errors?.roleId}</div>
              )} */}
            </div>
          </div>
          <div className="w-[350px] mb-4 ">
            <button
              type="button"
              onClick={() => {
                setModalVisible(false);
                setIsDecline(true);
              }}
              className={`w-[140px] rounded-[8px] border border-darkBlue text-darkBlue hover:border-[#EBEEFF] hover:text-darkBlue ${isDecline ? "text-[#56B77B] border-[#56B77B]" : ""} text-[16px] font-[700] px-[24px] py-[8px] ml-[25px]`}

            >
              {t("Cancel")}
            </button>
            <button
              onClick={(e: any) => {
                formik.handleSubmit();
                setIsSubmit(true);
              }}
              type="submit"
              className={`w-[140px] rounded-[8px] bg-[darkblue] hover:bg-[#1A275E] text-white ${isSubmit ? "bg-[#7CCF93]" : ""} text-[16px] mt-7 px-[24px] py-[8px] ml-[25px]`}

            >
              {t("SuBmit")}
            </button>
          </div>
        </div>
      </main>
    )
  );
};

export default Profile;