"use client";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import fetchWithToken from "../../utils/api";
import { useSelector } from "react-redux";
import Select from "react-select";

interface ShiftProps {
  isModalVisible: boolean | string | number;
  setModalVisible: React.Dispatch<
    React.SetStateAction<boolean | string | number>
  >;
  fetchShifts: () => void;
}

const Shift: any = ({ isModalVisible, setModalVisible, fetchShifts }) => {
  const userData = useSelector((state: any) => state.user?.user);
  const isAdd = isModalVisible === true;
  const { t, i18n } = useTranslation();
  const [userDepartment, setUserDepartment] = useState([]);
  const [departments, setDepartments] = useState<any>([]);

  const handleToggle = () => {
    formik.setFieldValue("is_rotation", !formik.values.is_rotation);
  };

  const formik = useFormik({
    initialValues: {
      start_time: "",
      end_time: "",
      shift_type: "",
      deptId: null as { _id: string;[key: string]: any } | null,
      is_rotation: false,
    },
    validationSchema: Yup?.object({
      start_time: Yup?.string()?.required(t("Required")),
      end_time: Yup?.string()?.required(t("Required")),
      shift_type: Yup?.string()?.required(t("Required")),
      deptId: Yup.object().required(t("Department is required")),
      is_rotation: Yup.boolean()
    }),
    onSubmit: async (values) => {
      try {
        await fetchWithToken(isAdd ? "/shifts" : `/shifts/${isModalVisible}`, {
          method: isAdd ? "POST" : "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            start_time: moment(values?.start_time, "HH:mm").format("HH:mm:ss"),
            end_time: moment(values?.end_time, "HH:mm").format("HH:mm:ss"),
            shift_type: values?.shift_type,
            department_id: values?.deptId?._id,
            is_rotation: values?.is_rotation,
          }),
        });
        setModalVisible(!isModalVisible);
        fetchShifts();
      } catch (error) {
        console.error("Error creating shift:", error);
      }
    },
  });

  const getShiftDetails = async (id: string | number) => {
    try {
      const data = await fetchWithToken(`/shifts/${id}`, {
        method: "GET",
      });
      formik?.setFieldValue("start_time", data.data?.start_time);
      formik?.setFieldValue("end_time", data.data?.end_time);
      formik?.setFieldValue("shift_type", data.data?.shift_type);
      formik?.setFieldValue(
        "deptId",
        departments?.filter(
          (each) => each?._id === data?.data?.department_id?._id
        )[0]
      );
      formik?.setFieldValue("is_rotation", data.data?.is_rotation);
    } catch (error) {
      console.error(t("Failed to fetch shifts:"), error);
    }
  };
  const fetchUserDepartment = async () => {
    try {
      const data = await fetchWithToken(
        `/departments/${userData?.department?._id}`,
        {
          method: "GET",
        }
      );
      setUserDepartment(
        data?.data?.sub_departments?.map((each: any) => ({
          value: each?._id,
          label: each?.name,
          user_level_limit: each?.user_level_limit,
        }))
      );
    } catch (error) {
      console.error(t("Failed to fetch departments:"), error);
    }
  };
  const fetchDepartments = async () => {
    try {
      const data = await fetchWithToken("/departments", {
        method: "GET",
      });

      setDepartments(
        data?.data?.map((each: any) => ({
          ...each,
          value: each?._id,
          label: each?.name,
        }))
      );
    } catch (error) {
      console.error(t("Failed to fetch departments:"), error);
    }
  };
  useEffect(() => {
    formik?.resetForm();
    if (
      typeof isModalVisible === "number" ||
      typeof isModalVisible === "string"
    )
      fetchUserDepartment();
    fetchDepartments();
    getShiftDetails(isModalVisible);
  }, [isModalVisible]);

  return (
    isModalVisible && (
      <main
        className="fixed top-0 bottom-0 left-0 right-0 
      bg-black bg-opacity-30 flex justify-center items-center z-[1]"
        onClick={() => setModalVisible(!isModalVisible)}
      >
        <div
          className="py-5 
          w-full max-w-[385px] h-auto max-h-[70%] 
          overflow-auto m-auto capitalize 
          bg-white rounded-lg flex flex-col items-center scrollbar-hidden"
          onClick={(e) => e?.stopPropagation()}
        >
          <div className="text-center text-lg font-bold">
            {!isAdd ? t("Edit") : t("Add")}
          </div>
          <div className="text-sm text-[#101010] w-full px-5 ">
            <div className="font-bold">{t("StartTime")}</div>
            <input
              type="time"
              placeholder={t("StartTimeL")}
              name="start_time"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="start_time"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.start_time}
              style={{
                borderColor:
                  formik?.touched?.start_time && formik?.errors?.start_time
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.start_time && formik?.errors?.start_time && (
                <div>{formik?.errors?.start_time}</div>
              )}
            </div>
            <div className="font-bold">{t("EndTime")}</div>
            <input
              type="time"
              placeholder={t("EndTimeL")}
              name="end_time"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="end_time"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.end_time}
              style={{
                borderColor:
                  formik?.touched?.end_time && formik?.errors?.end_time
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.end_time && formik?.errors?.end_time && (
                <div>{formik?.errors?.end_time}</div>
              )}
            </div>
            <div className="font-bold">{t("ShiftType")}</div>
            <input
              type="text"
              placeholder={t("ShiftType")}
              name="shift_type"
              className="w-[350px] h-[40px] border placeholder-[#5D6561] rounded-[8px] p-2 my-2 outline-none"
              id="type"
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values?.shift_type}
              style={{
                borderColor:
                  formik?.touched?.shift_type && formik?.errors?.shift_type
                    ? "#E23121"
                    : "#5D6561",
              }}
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.shift_type && formik?.errors?.shift_type && (
                <div>{formik?.errors?.shift_type}</div>
              )}
            </div>

            <div className="font-bold">{t("Department")}</div>
            <Select
              placeholder={t("Select")}
              options={departments}
              value={formik.values.deptId || t("Select departement")}
              name="deptId"
              onChange={(option) => formik.setFieldValue("deptId", option)}
              onBlur={formik.handleBlur}
              className="w-[350px] h-[40px]"
            />
            <div className="text-[12px] text-[#E23121] flex items-center h-[25px]">
              {formik?.touched?.deptId && formik?.errors?.deptId && (
                <div>{formik?.errors?.deptId}</div>
              )}
            </div>
            <div className="font-bold">{t("Rotation")}</div>
            <div className="flex items-center gap-3">
              <button
                type="button"
                onClick={handleToggle}
                className={`w-[40px] h-[20px] rounded-full ${formik.values.is_rotation ? "bg-green-500" : "bg-gray-300"
                  }`}
              >
                <div
                  className={`w-[18px] h-[18px] rounded-full bg-white transition-transform ${formik.values.is_rotation ? "translate-x-[20px]" : "translate-x-0"
                    }`}
                />
              </button>
            </div>
          </div>

          <div className="w-[350px]">
            <button
              type="button"
              onClick={() => setModalVisible(false)}
              className="w-[168px] h-[40px] rounded-[8px] border border-darkBlue text-darkBlue hover:border-[#EBEEFF] hover:text-darkBlue text-[16px] font-[700] px-[24px] py-[8px]"
              >
              {t("Cancel")}
            </button>
            <button
              onClick={(e: any) => {
                formik.handleSubmit();
              }}
              type="submit"
              className="w-[168px] rounded-[8px]  bg-[darkblue] hover:bg-[#1A275E] text-white p-2 text-[16px] mt-5 px-[24px] py-[8px] ml-[13px]"            >
              {t("Submit")}
            </button>
          </div>
        </div>
      </main>
    )
  );
};

export default Shift;
