import Cookies from "js-cookie";

async function fetchWithToken(
  endpoint: string,
  options: RequestInit & { responseType?: "json" | "arraybuffer" | "blob" } = {}
) {
  const token = Cookies.get("session");
  const headers = new Headers(options.headers || {});

  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}${endpoint}`,
    {
      ...options,
      headers,
    }
  );

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData?.message);
  }

  // Handle responseType for binary data
  if (options.responseType === "arraybuffer") {
    return response.arrayBuffer();
  }

  if (options.responseType === "blob") {
    return response.blob();
  }

  // Default to JSON
  if (response.status !== 204) {
    return response.json();
  }

  return null;
}

export default fetchWithToken;
